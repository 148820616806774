//
// _file-manager.scss
//

.file-manager-menu {
    @media (max-width: 991.98px) {
        position: relative;
        width: 100%;
        height: auto; // 컨텐츠에 맞게 자동 조절
    }

    li {
        padding: 5px 0px;

        a {
            color: var(--#{$prefix}body-color);
            font-weight: $font-weight-medium;
            transition: all 0.5s ease;

            &.active,
            &:hover,
            &[aria-expanded="true"]{
                color: var(--#{$prefix}success);
            }
        }

        .sub-menu {
            li {
                padding-left: 15px;
                position: relative;

                &::before {
                    content: "\2514";
                    position: absolute;
                    left: 0px;
                    font-family: Material Design Icons;
                    color: rgba(var(--#{$prefix}dark-rgb), 0.4);
                }
            }
        }
    }
}

.file-manager-sidebar {
    position: relative;
    background-color: var(--#{$prefix}secondary-bg);

    @media (min-width: 992px) {
        min-width: 300px;
        max-width: 300px;
        height: calc(100vh - #{$header-height} - #{$footer-height} - 8px);
    }

    @media (max-width: 991.98px) {
        position: relative;
        display: block;
        width: 100%;
        height: auto; // 컨텐츠에 맞게 자동 조절
        box-shadow: $box-shadow-lg;
    }
}

.file-menu-sidebar-scroll {
    @media (max-width: 991.98px) {
        height: auto; // 컨텐츠에 맞게 자동 조절
        max-height: none; // 최대 높이 제한 제거
        overflow-y: visible; // 스크롤 제거
    }
}

.file-manager-content {
    background-color: var(--#{$prefix}secondary-bg);
}

.file-manager-content-scroll {
    @media (max-width: 991.98px) {
        height: auto; // 컨텐츠에 맞게 자동 조절
    }

    .simplebar-content {
        height: auto; // 컨텐츠에 맞게 자동 조절
    }
}

// 나머지 코드는 유지...